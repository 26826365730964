
<template>
    <svg class="svg-jew" v-html="svgIcon">
    </svg>
</template>

<script setup>
const props = defineProps(['type'])

const headerSvgs = [
    'five_stone', 'necklase', 'seven_stone', 'bracelet', 'couple', 
    'eternity', 'stud', 'women_wedding', 'hoop', 'solitaire', 
    'cyoe', 'cyop', 'cyor', 'diamond', 'toi-moi','fashion-bracelet','fashion-earring','fashion_earrings_green','fashion_earrings_white','fashion_earrings_brown'
]

const svgIcon = computed(()=>{
    if(headerSvgs.includes(props.type)){
        return `<use xlink:href="/svg/jewelry.svg#${props.type}"> </use>`
    }
})
</script>

<style scoped>

</style>
