<template>
    <div class="cont_box">
        <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/testimonials')">
            testimonials
        </NuxtLink>
        <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/customer-service/shipping-policy')">
            services & values
        </NuxtLink>
        <a rel="nofollow" :href="'tel:'+$country.mobile">
            <SvgHeaderSvg :type="'telephone'" class="me-2" />
            Phone
        </a>
        <div class="chat_box" @click="openTawkChat">
            <SvgHeaderSvg :type="'chat'" class="me-2" />
            Chat
        </div>
       
        <a v-if="$country.is_india" rel="nofollow" href="https://wa.me/9920987654">
            <SvgHeaderSvg :type="'whatsapp'" class="me-2" />
            <span>WhatsApp</span>
        </a>
        <a rel="nofollow" :href="'mailto:'+$country.email"> 
            <SvgHeaderSvg :type="'email'" class="me-2" />
            Email
        </a>
        <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/appointment')">
            <SvgHeaderSvg :type="'virtual'" class="me-2" />
            Book An Appointment
        </NuxtLink>
    </div>
</template>


<script setup>
    const localePath = useLocalePath()
    const props = defineProps(['locale'])
    const emit = defineEmits(['hideShowMenu'])
    // const openTawkChat = () => {
    //         if (typeof window !== 'undefined' && window.Tawk_API) {
    //             window.Tawk_API.toggle()
    //         }
    //     }

    // Method to handle Tawk.to chat
    const openTawkChat = () => {
        try {
            if (import.meta.client && window.Tawk_API) {
            window.Tawk_API.toggle();
            console.log('clicked');
        }
        } catch (error) {
            console.log(error);
        }
    };

</script>

<style scoped>
@media (max-width: 1024px){
    a, .chat_box {
       align-items: center;
       display: flex;
       font-size: 14px;
       font-weight: 600;
       margin: 19.5px 0;
       padding: 0;
       color: #000;
       text-transform: uppercase;
       letter-spacing: 1.3px;
   }
   .cont_box{
       padding: 0 20px 20px 20px;
   }
}
</style>
